 <template lang="pug">
  v-container.pt-10.custom-container(fluid style="padding-bottom: 75px;")
    template(v-if="notifications.length > 0")
      v-card(flat elevation="0" ).notification__container
        v-toolbar.custom-toolbar-wrapper(v-if='!isMobile' dark="" height="75px" color="secondary")
          .toolbar-header.mr-auto
            v-row
              v-icon(size="30") mdi-bell
              h2 NOTIFICATIONS
        v-card-text.pa-0
          div(v-for="notification in notifications")
            v-card.notification.my-2
              v-img(max-width= '60px' contain :src="getIcon(notification.title)").ml-2
              v-col
                v-card-title
                  v-row
                    v-col
                      span.notification__date {{notification.created | moment}}
                    v-col.text-right.notification__time
                      | {{notification.created | momentHours}}
                v-card-text
                  p.notification__title.mb-2 {{notification.title}}
                  p.notification__description {{notification.description}}
    template(v-else)
      v-container(fluid).my-5
        v-card-title.justify-center.font-weight-light.primary--text You have no notifications at this time
          // v-skeleton-loader.mx-auto(ref="skeleton" boilerplate type="list-item-three-line" )
          //v-row
            v-col(cols="12")
              v-card-title.justify-center.font-weight-light
                | You haven't notifications yet
              v-card-text.body-1.text-center.font-weight-light.grey--text
                | Your notifications appear here.
</template>

<script>
  import moment from 'moment'
  import loaders from '@/plugins/mixins/loaders'
  import uploadIcon from '@/assets/icons/notifications/upload.png'
  import downloadIcon from '@/assets/icons/notifications/download.png'
  import purchaseIcon from '@/assets/icons/notifications/purchase.png'
  import withdrawIcon from '@/assets/icons/notifications/withdraw.png'
  import defaultIcon from '@/assets/icons/notifications/default.png'

  export default {

    filters: {
      moment: function (date) {
        return moment(date).format('MM-DD-YYYY')
      },
      momentHours: function (date) {
        return moment(date).format('HH:mm')
      },
    },

    mixins: [loaders],
    data () {
      return {
        title: 'Notifications',
      }
    },

    computed: {
      notifications () {
        return this.$store.getters['notifications/getNotifications']
      },
      isMobile () {
        return window.innerWidth < 1024
      },
    },

    created () {
      const vm = this
      vm.loadNotifications()
    },
    methods: {
      getIcon (name) {
        switch (name) {
          case 'media upload' :
            return uploadIcon

          case 'Video buy' :
            return purchaseIcon

          case 'media buy' :
            return purchaseIcon

          case 'media download' :
            return downloadIcon

          case 'Withdraw approved' :
            return withdrawIcon

          default :
            return defaultIcon
        }
      },
    },
  }
</script>

<style lang='scss' scoped>
.notification{
  display: flex;
  width: 95%;
  margin: 0 auto;
  border-radius: 23px !important;
  box-shadow: -7px 4px 38px rgba(0, 0, 0, 0.16);
  &__container{
    @media (max-width: 1024px){
      background: transparent;
    }
  }
  &__title{
    font-size: 20px;
    color: #808184;
  }
  &__date{
    color: var(--font-color-2) !important;
  }
  &__time{
    color: #808184;
  }
  &__description{
    color: #808184;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.2;
  }
}
</style>
